import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

export const StyledNews = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const NewsPreview = styled.li`
  margin-bottom: 2em;
`

export const NewsItem = styled(Link)`
  display: flex;
  flex-direction: column;
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const ImageWrapper = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 40%;
    max-width: 300px;
    flex-shrink: 0;
  }
`

export const PreviewImage = styled(Img)`
  width: 100%;
`

export const PreviewBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;

  @media (min-width: 992px) {
    padding: 1em 2em;
  }
`

export const PreviewTitle = styled.h4`
  font-size: 1rem;
  margin: 0 0 1em;
`

export const PreviewText = styled.p`
  margin: 0 0 1em;
`

export const PreviewFooter = styled.footer`
  margin-top: auto;
  color: var(--green1);
`
