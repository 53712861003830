import React from 'react'
import useNewsQuery from '../../hooks/useNewsQuery'
import {
  StyledNews,
  NewsPreview,
  NewsItem,
  PreviewImage,
  PreviewBody,
  PreviewTitle,
  PreviewFooter,
  ImageWrapper,
  PreviewText,
} from './styles'

const News = () => {
  const news = useNewsQuery()

  return (
    <StyledNews>
      {news.map(
        ({
          excerpt,
          fields: { slug },
          id,
          frontmatter: { title, date, featuredimage },
        }) => (
          <NewsPreview key={id}>
            <NewsItem to={slug}>
              {featuredimage && (
                <ImageWrapper>
                  <PreviewImage fluid={featuredimage.childImageSharp.fluid} />
                </ImageWrapper>
              )}
              <PreviewBody>
                <PreviewTitle>{title}</PreviewTitle>
                <PreviewText>{excerpt}</PreviewText>
                <PreviewFooter>{date}</PreviewFooter>
              </PreviewBody>
            </NewsItem>
          </NewsPreview>
        )
      )}
    </StyledNews>
  )
}

export default News
