import React from 'react'
import Layout from '../../components/Layout'
import Page from '../../components/Page'
import News from '../../components/News'
import Container from '../../components/shared/Container'
import SEO from '../../components/Seo/Seo'

const BlogIndexPage = () => (
  <Layout>
    <SEO />
    <Page>
      <Container>
        <News />
      </Container>
    </Page>
  </Layout>
)

export default BlogIndexPage
